@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

html,
body {
  background-color: hsl(27, 66%, 92%);
  font-family: 'DM Sans', sans-serif;
}

.test-background {
  background-color: white;
}

.w-50vw {
  width: 50vw;
}

.components-width {
  width: 38.5%;
  max-width: 1440px;
}

@media only screen and (max-width: 770px) {
  .components-width {
    width: 90%;
  }
}
